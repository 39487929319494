var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"text-center spinner"},[_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"variant":"primary","type":"grow","label":"Spinning"}})],1)]),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('iq-card',[_c('b-col',{attrs:{"md":"12"}},[[_c('Fullcalendar',{ref:"mycalendar",attrs:{"custom-buttons":{
                prev: {
                  text: 'PREV',
                  click: _vm.prevMonth,
                },
                next: {
                  text: 'NEXT',
                  click: _vm.nextMonth,
                },
              },"locale":"es","plugins":_vm.calendarPlugins,"header":{
                left: '',
                center: 'title',
                right: 'prev today next',
              },"buttonText":{
                today: 'Hoy',
                month: 'Mes',
                week: 'Semana',
                day: 'Día',
                list: 'Listado',
              },"weekends":true,"selectable":true,"editable":true,"events":_vm.formatCalendar},on:{"eventClick":_vm.handleEventClick}})]],2)],1)],1)],1),_c('b-modal',{ref:"modal-details",attrs:{"size":"lg","id":"modal-details","cancel-title":"Cancelar","ok-only":"","title":_vm.details.modalTitle}},[_c('OrderDetailComponent',{attrs:{"dataId":_vm.orderId,"idList":_vm.ids,"enableButtons":false,"ocultar":_vm.hidden}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }