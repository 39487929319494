var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('iq-card',{scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"new-user-info"},[(_vm.loading)?_c('b-col',{staticClass:"text-center spinner",attrs:{"md":"12"}},[_c('b-spinner',{attrs:{"variant":"primary","type":"grow","label":"Spinning"}})],1):_vm._e(),_c('b-row',[_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Nombre:","label-for":"name"}},[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"text","placeholder":"Nombre del usuario"},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Teléfono:","label-for":"phone"}},[_c('ValidationProvider',{attrs:{"name":"Teléfono","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['###-####', '####-####']),expression:"['###-####', '####-####']"}],class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"tel","name":"phone","id":"phone","placeholder":"Teléfono"},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Email","label-for":"email"}},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"text","name":"email","id":"email","placeholder":"Correo electrónico"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Role:","label-for":"role"}},[_c('ValidationProvider',{attrs:{"name":"Rol","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors[0] ? false : (_vm.user.role ? true : null),"options":_vm.roles},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Contraseña:","label-for":"role"}},[_c('ValidationProvider',{attrs:{"name":"Contraseña","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"text","placeholder":"Contraseña"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1),_c('hr'),_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v(_vm._s(_vm.status === 'add' ? 'Crear usuario' : 'Actualizar usuario'))])],1)]},proxy:true}],null,true)})],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }