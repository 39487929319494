var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"mt-4",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"vid":"name","name":"Nombre completo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Nombre completo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.name),expression:"user.name"}],class:'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"text","id":"name","aria-describedby":"emailHelp","placeholder":"Su nombre completo"},domProps:{"value":(_vm.user.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"phone","name":"Teléfono","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"phone"}},[_vm._v("Nombre completo")]),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:(['###-####', '####-####']),expression:"['###-####', '####-####']"},{name:"model",rawName:"v-model",value:(_vm.user.phone),expression:"user.phone"}],class:'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"tel","id":"name","aria-describedby":"emailHelp","placeholder":"Teléfono"},domProps:{"value":(_vm.user.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "phone", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"name","name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Correo electrónico")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],class:'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"email","id":"email","aria-describedby":"emailHelp","placeholder":"Correo electrónico"},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "email", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"password","name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Contraseña")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],class:'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"password","id":"password","placeholder":"Contraseña"},domProps:{"value":(_vm.user.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "password", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('div',{staticClass:"d-inline-block w-100"},[_c('div',{staticClass:"custom-control custom-checkbox d-inline-block mt-2 pt-1"},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"checkbox","id":_vm.formType}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":_vm.formType}},[_vm._v("I accept "),_c('a',{attrs:{"href":"#"}},[_vm._v("Términos y Condiciones")])])]),_c('button',{staticClass:"btn btn-primary float-right",attrs:{"type":"submit"}},[_vm._v("Registrarse")])]),_c('div',{staticClass:"sign-info"},[_c('span',{staticClass:"dark-color d-inline-block line-height-2"},[_vm._v(" Ya posee una cuenta? "),(_vm.$route.meta.dark)?_c('router-link',{staticClass:"iq-waves-effect pr-4",attrs:{"to":"/dark/auth/sign-in1"}},[_vm._v(" Inicie sesión ")]):_c('router-link',{staticClass:"iq-waves-effect pr-4",attrs:{"to":"/auth/sign-in1"}},[_vm._v(" Inicie sesión ")])],1),_c('social-login-form')],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }